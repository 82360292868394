'use strict';

Gri.module({
  name: 'tab-alpha',
  ieVersion: null,
  $el: $('.tab-alpha'),
  container: '.tab-alpha',
  fn: function () {
    function listWidht() {
      var tabwidth = $('.tab-alpha').width();
      $('.tab-alpha-list li').width(tabwidth / $('.tab-alpha-list li').length - 0.145);
    }
    function referencesfilter() {
      var change;
      $('.tab-alpha.references li').click(function () {
        change = $(this).find('a').data("category");
        console.log('change');
        $('.isotope-alpha').isotope({ filter: "." + change });
      });
    }

    $(window).resize(function() {
      listWidht();
    });
    $(window).on('load', function() {
      listWidht();
    });

    $("#7-thumb-button").parent().remove();
    referencesfilter();
  }
});
